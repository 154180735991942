<template>
  <div>
    <h2 class="text-xl font-bold uppercase text-primary-900 pl-3 mt-3 mb-4">
      {{`${contact.prenom && contact.nom ? `${contact.prenom} ${contact.nom}` : "Notre service formation"}`}}
    </h2>

    <div v-if="contact.telephone || contact.email" class="flex items-center my-8">
      <div v-if="contact.telephone" class="w-1/2 flex flex-grow items-center justify-center px-5">
        <font-awesome-icon icon="phone-alt"
                           size="3x"
                           class="text-primary-900"/>
        <div class="font-bold leading-snug px-5">
          <div class="text-md uppercase text-accent-600">
            Par téléphone
          </div>
          <div class="text-2xl">
            <span>{{contact.telephone}}</span>
          </div>
        </div>
      </div>

      <div v-if="contact.email"
           class="w-1/2 flex flex-grow items-center justify-center px-5"
           v-bind:class="{'border-l border-core-400': contact.telephone}">
        <font-awesome-icon icon="envelope"
                           size="4x"
                           class="text-primary-900"/>
        <div class="truncate font-bold leading-snug px-5">
          <div class="text-md uppercase text-accent-600">
            Par e-mail
          </div>
          <div class="text-2xl truncate italic hover:underline">
            <span class="break-words">
              <a v-tooltip="`${contact.email}`"
                 v-bind:href="`mailto:${contact.email}`">
                {{contact.email}}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-wrap items-center justify-center pb-6 px-12">
      <div class="w-full text-md uppercase text-accent-600 font-bold">
        Aucune information de contact disponible
      </div>
      <div class="w-full text-sm">
        Veuillez vous rapprocher de votre organisme de formation
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: ['current_session'],
    computed: {
        contact() {
            const session = this.current_session[0];
            if (session.contact) {
                return {
                    ...session.contact
                };
            } else if (session.organisateur) {
                return {
                    ...session.organisateur
                };
            }
            return {};
        }
    },
    created() {
    },
};
</script>
<style lang="scss" scoped>
</style>
