<template>
  <div class="fiche-catalogue" v-bind:style="cssVars">

    <spinner v-if="loading"
             sizes="w-20 h-20"
             thickness="border-8"/>

    <div v-else class="fiche-catalogue__inner">

      <router-link v-bind:to="{name: 'catalogue'}"
                   class="fiche-catalogue__btn-back">
        <font-awesome-icon class="btn-back__icon"
                           icon="chevron-circle-left"
                           size="1x"/>
        <span class="btn-back__text">Retour au catalogue</span>
      </router-link>

      <section class="inner__column column--start">

        <div class="column__content">

          <div ref="scrollable_start" class="content__inner">

            <h3 class="content__title">Compétences</h3>

            <label-list label="Format de formation"
                        type="type_formation"
                        query_type="typesIds"
                        v-bind:list="[fiche.type]"
                        class="content__item"/>

            <label-list label="Métier"
                        type="metier"
                        query_type="metiersIds"
                        v-bind:list="convertMetiersLabels"
                        class="content__item"/>

            <label-list label="Famille de produits"
                        type="famille"
                        v-bind:query_type="queryTypesFamilies"
                        v-bind:list="fetchFamilies"
                        class="content__item"/>

            <label-list label="Public Concerné"
                        type="public_concerne"
                        query_type="publicConcernesIds"
                        v-bind:list="mergePublicConcernes"
                        bullet_list
                        class="content__item"/>

            <richtext-block v-if="fiche.prerequis"
                            v-bind:source="fiche.prerequis"
                            label="Pré-requis"
                            class="content__item"/>

            <richtext-block v-if="fiche.objectifs"
                            v-bind:source="fiche.objectifs"
                            label="Objectifs professionnels"
                            class="content__item "/>

            <richtext-block v-if="fiche.beneficesParticipants"
                            v-bind:source="fiche.beneficesParticipants"
                            label="Bénéfices Participants"
                            class="content__item"/>

          </div>
          <simplebar-scroll colors="bg-core-700" target="scrollable_start"/>

        </div>

      </section>

      <section class="inner__column column--center">

        <div class="column__content">

          <div ref="scrollable_middle" class="content__inner">

            <article class="content__main">

              <div class="main__headline">
                <h1 v-if="fiche.titre" class="headline__title">
                  {{fiche.titre}}
                </h1>
                <h2 v-if="fiche.sousTitre" class="headline__subtitle">{{fiche.sousTitre}}</h2>
              </div>
              <div v-if="fiche.certifiant" class="ribbon ribbon-top-right">
                <div class="ribbon__inner">
                  <font-awesome-icon icon="graduation-cap" size="1x"/>
                  <span class="pl-0.5">Certifiant</span>
                </div>
              </div>

              <promotion-box v-if="fiche.messagePromotionnel"
                             v-bind:source="fiche.messagePromotionnel"/>

              <div class="flex my-3">
                <div v-if="fiche.themesComplementaires.length"
                     class=" w-1/2">
                  <label-list type="thematique"
                              query_type="thematiquesIds"
                              label="Thématiques"
                              v-bind:list="fiche.themesComplementaires"/>
                </div>

                <div v-if="fiche.collections.length"
                     class="text-right w-1/2">
                  <label-list type="collection"
                              query_type="collectionsIds"
                              label="Collections"
                              v-bind:list="fiche.collections"
                              right/>
                </div>
              </div>
              <div class="main__body">
                <richtext-block v-if="fiche.preambule"
                                v-bind:source="fiche.preambule"
                                label="Préambule"
                                class="content__item"/>

                <richtext-block v-if="fiche.questionsTraitees"
                                v-bind:source="fiche.questionsTraitees"
                                label="Questions Traitées"
                                class="content__item "/>

                <richtext-block v-if="fiche.moyensPedagogiques"
                                v-bind:source="fiche.moyensPedagogiques"
                                label="Moyens Pédagogiques"
                                class="content__item"/>

                <richtext-block v-if="fiche.infosParticulieres"
                                v-bind:source="fiche.infosParticulieres"
                                label="Informations Particulières"
                                class="content__item"/>

                <richtext-block v-if="fiche.contenusLies"
                                v-bind:source="fiche.contenusLies"
                                label="Contenus Liés"
                                class="content__item"/>
              </div>

            </article>

          </div>
          <simplebar-scroll colors="bg-core-700" target="scrollable_middle"/>

        </div>

      </section>

      <section class="inner__column column--end">

        <div class="column__content">

          <div ref="scrollable_end" class="content__inner">

            <h3 class="content__title content__title--owner">
              <span>{{fiche.proprietaire}}</span>
              <span>-</span>
              <span>{{fiche.produitReference}}</span>
            </h3>
            <label-list v-if="fiche.tags.length"
                        type="tag"
                        query_type="tagsIds"
                        v-bind:list="fiche.tags"
                        class="content__item"/>
            <div v-if="fiche.ajouterVideo && fiche.urlVideo" class="content__video">
              <iframe class="video"
                      v-bind:src="`https://www.youtube.com/embed/${getYoutubeVideoId(fiche.urlVideo)}`"
                      frameborder="0"
                      allow="encrypted-media"
                      allowfullscreen></iframe>
            </div>
            <hr v-if="fiche.ajouterVideo && fiche.urlVideo" class="content__hr"/>
            <h3 class="content__title">Modalités</h3>
            <template v-if="fiche.programmableInter">
              <div class="session__selection">
                <select-box v-model="current_ville"
                            is_update_method
                            name="ville"
                            label="Ville"
                            tracking="id"
                            v-bind:disabled="selectable_villes.length <= 1"
                            v-bind:placeholder="selectable_villes.length !== 0 ? 'Sélectionnez une ville': 'Aucune ville disponible'"
                            v-bind:categories="selectable_villes"
                            message_key="city"
                            v-on:input="updateValue"/>
              </div>

              <div class="session__selection">
                <select-box v-model="current_date"
                            is_update_method
                            name="date"
                            label="Date"
                            v-bind:placeholder="selectable_villes.length !== 0 ? 'Sélectionnez une date': 'Aucune date disponible'"
                            v-bind:disabled="available_dates.length <= 1"
                            v-bind:categories="selectable_dates"
                            v-on:input="updateValue"/>
              </div>
            </template>

            <div class="content__aside">

              <template v-if="fiche.programmableInter">
                <div class="aside__calendar">
                  <button class="calendar__btn"
                          v-on:click.stop.prevent="modal_sessions_calendar = true"
                          v-bind:disabled="isCalendarDisabled">
                    <font-awesome-icon class="calendar__btn__icon"
                                       v-bind:icon="['far','calendar-alt']"
                                       size="4x"/>
                    <span class="calendar__btn__text">
                      Voir le calendrier
                    </span>
                  </button>
                </div>
              </template>

              <div class="aside__info">

                <p v-if="fiche.dureeMinutes" class="info">
                  <span class="info__label">
                    Durée&nbsp;:
                  </span>
                  <span class="info__value">
                    {{fiche.dureeMinutes | minutesIntoHours}}
                  </span>
                  <span v-if="fiche.dureeJours"
                        class="pl-1">
                    ({{fiche.dureeJours | displayJourString}})
                  </span>
                </p>

                <p v-if="fiche.prixCatalogue" class="info">
                  <span class="info__label">
                    Tarif&nbsp;:
                  </span>
                  <span class="info__value">
                    {{fiche.prixCatalogue}}&nbsp;€
                  </span>
                </p>
              </div>

              <div v-if="fiche.programmableIntra"
                   v-on:click.prevent="modal_formulaire_intra = true"
                   class="aside__intra__btn">
                <font-awesome-icon class="intra__btn__icon"
                                   icon="users"/>
                <span class="intra__btn__text">
                  Organisez&nbsp;cette&nbsp;formation dans&nbsp;vos&nbsp;locaux
                </span>
              </div>

              <hr class="content__hr"/>

            </div>

          </div>
          <simplebar-scroll colors="bg-core-700" target="scrollable_end"/>

          <div class="content__session">
            <button v-if="fiche.programmableInter && isSubscriptionAvailable"
                    class="session__validate"
                    v-tooltip="isInscriptionDisabled ? { content: 'Aucune session sélectionnée'} : null"
                    v-bind:disabled="isInscriptionDisabled"
                    v-on:click="toInscriptionRoute">
              <a v-bind:href="!isInscriptionDisabled ? toInscriptionRoute : null" class="session__validate--link">
                <font-awesome-icon class="session__validate__icon"
                                   icon="graduation-cap"
                                   size="lg"/>
                <span class="session__validate__text">Inscription</span>
              </a>
            </button>

            <button v-if="fiche.programmableInter && !isSubscriptionAvailable"
                    class="session__validate"
                    v-on:click="onOpenModalContact(current_session_id)">
              <font-awesome-icon class="session__validate__icon"
                                 icon="phone-alt"
                                 size="lg"/>
              <span class="session__validate__text">Contactez-nous</span>
            </button>
          </div>

        </div>

      </section>

    </div>

    <modal-box v-if="modal_formulaire_intra"
               v-on:close="modal_formulaire_intra = false"
               v-bind:footer="false"
               v-bind:width_class="'w-2/3'">

      <template #modal-header>
        <h1 class="text-xl">Demande de formation</h1>
      </template>
      <template #modal-body>
        <formulaire-intra
          v-bind:titre="fiche.titre"
          v-bind:reference="fiche.reference"
        />
      </template>
    </modal-box>

    <modal-box v-if="modal_sessions_calendar"
               v-on:close="closeSessionsCalendarModal"
               v-bind:footer="false"
               overflow_body>

      <template #modal-header>
        <h1 class="text-xl">Calendrier des formations</h1>
      </template>

      <template #modal-body>
        <calendar-table v-if="fiche.sessions"
                        v-bind:sessions="list_sessions"
                        v-bind:duree_minutes="fiche.dureeMinutes"
                        v-bind:duree_jours="fiche.dureeJours"/>
      </template>

      <template #modal-footer></template>

    </modal-box>

    <modal-box v-if="modal_contact_inscription"
               v-on:close="modal_contact_inscription = false"
               v-bind:footer="false"
               v-bind:width_class="'w-2/3'">

      <template #modal-header>
        <h1 class="text-xl">Contact</h1>
      </template>
      <template #modal-body>
        <contact-card v-bind:current_session="current_session_contact"/>
      </template>
    </modal-box>

  </div>
</template>

<script>
import {compareAsc} from 'date-fns';

import helpers_filters from '@/mixin/helpers_filters';
import session_dates_functions from '@/mixin/session_dates_functions';

import {map_metiers} from '@/assets/data/data_maps.js';

import spinner from '@/components/utils/spinner.vue';
import simplebarScroll from '@/components/utils/simplebar-scroll.vue';

import contactCard from '@/components/utils/contact-card.vue';
import modalBox from '@/components/utils/modal-box.vue';
import calendarTable from '@/components/utils/calendar-table.vue';
import labelList from '@/components/utils/label-list.vue';
import selectBox from '@/components/utils/select-box.vue';
import richtextBlock from '@/components/utils/richtext-block.vue';
import promotionBox from '@/components/utils/promotion-box.vue';

import formulaireIntra from '@/components/utils/formulaire-intra.vue';

import {API} from '@/http-common';

import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateObserver} = createNamespacedHelpers('observer');

export default {
    components: {
        spinner,
        simplebarScroll,
        modalBox,
        contactCard,
        calendarTable,
        labelList,
        selectBox,
        richtextBlock,
        promotionBox,
        formulaireIntra
    },
    mixins: [helpers_filters, session_dates_functions],
    props: ['fiche_id', 'preview_id'],
    data() {
        return {
            loading: false,
            modal_sessions_calendar: false,
            modal_formulaire_intra: false,
            modal_contact_inscription: false,
            fiche: [],
            errors: [],
            current_ville: [],
            current_date: [],
            current_session_id: null,
            session_contact_id: null,
            error_status_code: null
        };
    },
    computed: {
        ...mapStateObserver({
            offset: (state) => state.offset,
        }),
        cssVars() {
            return {
                '--navbar-height': this.offset + 'px',
            };
        },
        fetchFamilies() {
            const formation_continue = this.fiche.familleFormationContinue && {id: 1, label: 'Formation continue'};
            const stage = this.fiche.familleStage && {id:2, label: 'Stage'};

            const families = [];
            [formation_continue, stage].forEach((family) => {
                family && families.push(family);
            });
            return families;
        },
        queryTypesFamilies() {
            return {1: 'familleFormationContinue', 2: 'familleStage'};
        },
        list_sessions() {
            return this.fiche.sessions ? this.listSessions(this.fiche.sessions) : [];
        },
        isSubscriptionAvailable() {
            let isAvailable = true;

            const current_session = this.fiche.sessions.filter((session) => {
                return session.sessionId === this.current_session_id;
            });

            if (current_session[0] && Object.prototype.hasOwnProperty.call(current_session[0], 'dateLimiteInscription')) {
                const current_limit_date = this.parseToFrDate(current_session[0].dateLimiteInscription);

                isAvailable = compareAsc(current_limit_date, new Date()) == 1 ? true : false;
            }
            return isAvailable;
        },
        toInscriptionRoute() {
            return `https://${process.env.VUE_APP_HOST_INSCRIPTION}/${this.fiche_id}/session/${this.current_session_id}`;
        },
        isCalendarDisabled() {
            return this.fiche.sessions && this.fiche.sessions.length <= 1;
        },
        isInscriptionDisabled() {
            return !this.current_ville.length || !this.current_date.length;
        },
        available_dates() {
            const dates = [];
            if (this.current_ville.length) {
                const current_id = this.current_ville[0].id;
                const payload = this.list_sessions.filter(({name}) => {
                    return name.id === current_id;
                });
                dates.push(...payload[0].dates);
            }
            return dates;
        },
        selectable_villes() {
            const array_villes = this.list_sessions.map(({name}) => {
                return {id: name.id, label: name.nom};
            });
            return array_villes;
        },
        selectable_dates() {
            const array_selection = [];
            if (this.available_dates.length) {
                const dates_list = this.available_dates;
                dates_list.forEach((date) => {
                    const entry = {
                        id: date.id,
                        label: `${date.label}`,
                        $isDisabled: false
                    };
                    array_selection.push(entry);
                });
            }
            return array_selection;
        },
        current_session_contact() {
            return this.fiche.sessions.filter((session) => session.sessionId === this.session_contact_id);
        },
        convertMetiersLabels() {
            return this.fiche.metiers.map(metier => {
                return {id: metier.id, label: map_metiers[metier.label].label};
            });
        },
        mergePublicConcernes() {
            const publics = this.fiche.publicConcernes;
            const autres = this.fiche.autrePublic;
            const merge = autres ? [...publics, {id: 99, label: autres, isAutre: true}] : publics;
            return merge;
        },
    },
    watch: {
        selectable_villes: 'initCurrentVille',
        current_ville(newVal) {
            if (newVal.length) {
                const data = {
                    name: 'date',
                    value: this.selectable_dates[0]
                };
                this.updateValue(data);
            }
        }
    },
    created() {
        this.fetchCurrentFiche();
    },
    mounted() {
        this.$root.$on('open_modal_contact', (id) => {
            this.onOpenModalContact(id);
        });
    },
    methods: {
        fetchCurrentFiche() {
            this.loading = true;
            if (this.fiche_id) {

                API.get(`catalogue/fiche/${this.fiche_id}`)
                    .then((response) => {
                        this.fiche = response.data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        this.errors.push(e);
                    });
            } else {
                API.get(`catalogue/fiche/preview/${this.preview_id}`)
                    .then((response) => {
                        this.fiche = response.data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        this.errors.push(e);
                    });
            }
        },
        initCurrentVille() {
            if (this.selectable_villes.length) {
                this.current_ville = [this.selectable_villes[0]];
            }
        },
        updateValue(data) {
            const target = `current_${data.name}`;
            this[target] = [data.value];
            this.current_session_id = target === 'current_date' ? data.value.id : null;
        },
        onOpenModalContact(id) {
            this.session_contact_id = id;
            this.modal_contact_inscription = true;
        },
        getYoutubeVideoId(url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = url.match(regExp);

            return match && match[2].length === 11
                ? match[2]
                : null;
        },
        closeSessionsCalendarModal() {
            if (!this.modal_contact_inscription) {
                this.modal_sessions_calendar = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';
.fiche-catalogue {
    @apply flex items-center justify-center h-full;
    .fiche-catalogue__inner {
        height: calc(100vh - var(--navbar-height) - 5rem);
        @apply bg-core-100 flex items-stretch justify-around w-11/12 mx-auto;

        .fiche-catalogue__btn-back {
            @apply fixed bg-white rounded-lg uppercase font-bold text-sm pt-1.5 pb-1 leading-none shadow-sm px-3 hover:text-primary-800 focus:text-primary-800 hover:shadow transition duration-150 ease-out;

            top: calc(var(--navbar-height) + 0.5rem);
            left: 0.75rem;

            .btn-back__icon {}
            .btn-back__text {
                @apply ml-2;
            }
        }

        .inner__column {
            @apply relative;

            /deep/ .simplebar-content {
                @apply h-full;
            }

            /deep/ .simplebar-scrollbar::before {
                opacity: 0.1;
                @apply bg-core-600 transition ease-out duration-300;
            }
            &:hover /deep/ .simplebar-scrollbar::before {
                @apply opacity-25;
            }

            .column__content {
                @apply relative mx-auto h-full;

                .content__inner {
                    @apply h-full overflow-y-auto rounded-lg shadow bg-white;

                    .content__title {
                        @apply mb-8;
                        &.content__title--owner {
                          @apply mb-3 text-lg;
                        }
                    }

                    .content__video {
                      @apply relative overflow-hidden w-full my-6 bg-core-300;

                      /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
                      padding-top: 56.25%;
                      .video {
                        /* Then style the iframe to fit in the container div with full height and width */
                        @apply absolute w-full h-full inset-0;
                      }
                    }

                    .content__item {
                        @apply mb-6;
                    }
                    .content__hr {
                        @apply my-6 mx-1 border-core-300;
                    }
                }
            }

            &.column--start {
                @apply w-1/4 h-full;

                .column__content {
                    @apply w-11/12;

                    .content__inner {
                        @apply bg-white py-6 px-8;
                    }
                }
            }

            &.column--center {
                @apply w-2/4 h-full shadow-xl;
                .column__content {
                    .content__inner {
                        @apply bg-white py-8;
                        .content__img {
                            @apply w-full object-cover bg-core-600 h-72;

                            &.content__img--default {
                                @apply h-72;
                                @include placeholder-img();
                            }
                        }

                        .content__main {
                            @apply flex flex-col max-w-full pr-12 pl-10;

                            .main__headline {
                                @apply flex flex-col mb-3 w-11/12;

                                .headline__title {
                                    @apply text-primary-800 leading-none text-2xl;
                                }

                                .headline__subtitle {
                                    @apply text-core-500 font-bold text-lg leading-none my-3;
                                }
                            }

                            .main__time {
                              @apply flex flex-wrap justify-start my-3 text-accent-600 italic text-sm leading-none;

                              .time__icon {
                                @apply mr-1;
                              }

                              .time__text {}
                            }

                            .main__tags {
                              @apply flex flex-wrap items-center;
                            }

                            .main__body {
                                @apply my-4;
                            }

                        }

                    }
                }
            }

            &.column--end {
                @apply w-1/4 h-full;

                .column__content {
                    @apply w-11/12;

                    /deep/ .multiselect__content {
                        @apply w-full;
                    }

                    .simplebar-scroll {
                        margin-bottom: calc(0.75rem + 4rem);
                    }

                    .content__inner {
                        @apply bg-white py-6 px-8 mb-8 rounded-b-none;
                        height: calc(100% - 4rem);

                        .content__aside {
                            @apply flex flex-wrap items-center mt-6;

                            .aside__calendar {
                                @apply w-1/2;

                                .calendar__btn {
                                    @apply cursor-pointer bg-core-100 flex flex-wrap justify-center py-2 mr-4 rounded transition ease-out duration-150 text-core-700 border hover:shadow hover:text-primary-900 bg-white disabled:opacity-25 disabled:text-core-700 disabled:cursor-not-allowed;
                                    &:active {
                                        @apply shadow-inner text-core-700;
                                    }

                                    .calendar__btn__icon {
                                        @apply p-2;
                                    }

                                    .calendar__btn__text {
                                        @apply text-sm my-2 px-2 text-center inline-block w-full leading-none;
                                    }
                                }
                            }

                            .aside__info {
                                @apply w-1/2;

                                &.aside__info--intra-only {
                                  @apply w-full;
                                }

                                .info {
                                    @apply flex flex-wrap py-1 px-3 font-semibold text-sm;
                                    .info__label {
                                        @apply text-core-700 mr-2;
                                    }
                                    .info__value {}
                                }

                            }

                            .aside__intra__btn {
                                @apply cursor-pointer w-full flex justify-center items-center bg-core-600 text-core-100 px-4 py-3 rounded mt-5 shadow;

                                &:hover {
                                    @apply bg-primary-900;
                                }

                                .intra__btn__icon {
                                    @apply w-8 pr-3 flex;
                                }
                                .intra__btn__text {
                                    @apply w-auto flex leading-none text-sm font-bold text-center;
                                }
                            }

                        }
                      }
                      .session__selection {
                        @apply w-full mb-3;
                      }
                      .content__session {
                        @apply absolute bottom-0 bg-white shadow-md left-0 right-0 w-full flex flex-wrap items-end h-16;

                        .session__validate {
                          @apply w-full h-full uppercase text-center font-bold text-core-100 bg-primary-900 flex justify-center items-center self-end rounded-b shadow leading-none;

                          .session__validate--link {
                              @apply flex items-center justify-center w-full h-full p-4;
                          }

                          &:hover {
                            @apply text-white bg-primary-800;
                          }
                          &:disabled {
                            @apply opacity-75 bg-core-500 cursor-not-allowed;
                          }
                          .session__validate__icon {
                            @apply inline-flex mb-1;
                          }
                          .session__validate__text {
                            @apply inline-flex mx-5;
                          }
                        }
                      }
                }
            }
        }
    }
}
</style>
