<template>
  <div class="w-full flex flex-wrap">
    <form-label v-bind:label="label"
                v-bind:icon="label_icon"
                class="mb-2 leading-none font-bold"/>

    <textarea ref="textarea"
              v-model="input_value"
              class="textarea-block"
              type="text"
              v-on:input="updateValue"
              v-bind:rows="rows"
              v-bind:maxlength="maxlength"
              v-bind:placeholder="placeholderComputed"></textarea>

    <div v-if="maxlength"
         class="flex w-full my-0.5 text-xs text-core-600 leading-none">
      Caractères restants : {{maxlength - input_value.length}}
    </div>

  </div>
</template>

<script>
import formLabel from '@/components/utils/form-label.vue';

export default {
    components: {
        formLabel
    },
    props: {
        value: {
            type: String
        },
        name: {
            type: String
        },
        label: {
            type: String,
            default: 'Input'
        },
        label_icon: {
            type: [String, Array]
        },
        placeholder: {
            type: String
        },
        maxlength: {
            type: [String, Number]
        },
        rows: {
            type: [String, Number]
        },
        autogrow: {
            type: Boolean
        }
    },
    data() {
        return {
            input_value: this.value
        };
    },
    computed: {
        placeholderComputed() {
            return this.placeholder || `Saisir ${this.label.toLowerCase()}`;
        }
    },
    watch: {
        value(newVal, oldVal) {
            if (!newVal) {
                this.input_value = '';
            } else {
                this.input_value = newVal;
            }
        }
    },
    mounted() {
        const textarea = this.$refs.textarea;

        if (this.autogrow) {
            textarea.addEventListener('input', this.autoExpand);
        }
    },
    beforeDestroy() {
        const textarea = this.$refs.textarea;
        textarea.removeEventListener('input', this.autoExpand);
    },
    methods: {
        updateValue(e) {
            this.$emit('input', this.input_value);
        },
        autoExpand(e) {
            const field = e.target;

            // Reset field height
            field.style.height = 'inherit';
            // Get the computed styles for the element
            const computed = window.getComputedStyle(field);
            // Calculate the height
            const height =
                        parseInt(computed.getPropertyValue('border-top-width'))
                        + parseInt(computed.getPropertyValue('border-bottom-width'))
                        + field.scrollHeight;
            // + parseInt(computed.getPropertyValue('padding-top'))
            // + parseInt(computed.getPropertyValue('padding-bottom'));

            field.style.height = height + 'px';
        }
    }

};
</script>

<style lang="scss" scoped>
.textarea-block {
  @apply appearance-none flex w-full bg-white text-core-700 border border-core-700 rounded py-3 px-4;

  &:focus {
    @apply outline-none border-core-500;
  }
}
</style>
